<template>
  <div class="homewrap">
    <el-container style="height: 100%" direction="vertical">
      <!-- 头部 -->
      <el-header>
        <el-menu :default-active="calcRoute" class="el-menu-demo" mode="horizontal" @select="handleSelect"
          background-color="#2d3a4b" text-color="#fff" active-text-color="#00b5f9" :router="true">
          <el-menu-item index="/dashboard"><router-link to="/dashboard" class="link">Home</router-link></el-menu-item>
          <el-submenu index="">
            <template slot="title" class="link">Shipment</template>
            <el-menu-item index="/shipmentlist" class="account"><router-link to="/shipmentlist" class="link">Shipment
                List</router-link></el-menu-item>
            <el-menu-item index="/shipmentmeasure" class="account"><router-link to="/shipmentmeasure"
                class="link">Shipment Measure</router-link></el-menu-item>

            <el-menu-item index="/putshipment" class="account"><router-link to="/putshipment" class="link">Put
                Shipment</router-link></el-menu-item>
            <el-menu-item index="/statistics" v-show="role == 1" class="account"><router-link to="/statistics"
                class="link">Shipment Statistics</router-link></el-menu-item>
            <el-menu-item index="/loglist" class="account"><router-link to="/loglist" class="link">Shipment
                Log</router-link></el-menu-item>
          </el-submenu>
          <el-menu-item index="/jobs" v-show="role == 1"><router-link to="/jobs"
              class="link">Blocks</router-link></el-menu-item>
          <el-menu-item index="/return"><router-link to="/return" class="link">Reprint Labels</router-link></el-menu-item>
          <el-submenu index="1" v-show="role == 1">
            <template slot="title" class="link">Operator</template>
            <el-menu-item index="/addcount" class="account"><router-link to="/addcount" class="link">Add
                Operator</router-link></el-menu-item>
            <el-menu-item index="/listcount" class="account"><router-link to="/listcount" class="link">Operator
                List</router-link></el-menu-item>
          </el-submenu>
          <el-submenu index="0" class="user">
            <template slot="title" class="link">Welcome {{ name }}! <span class="iconfont icon-yonghu"></span></template>
            <el-menu-item index="/editpassword" class="account"><router-link to="/editpassword"
                class="link">ChangePassword</router-link></el-menu-item>
            <el-menu-item index="/preference" class="account"><router-link to="/preference"
                class="link">PreferenceSet</router-link></el-menu-item>
            <el-menu-item index="/noticelist" class="account" v-show="role == 1"><router-link to="/noticelist"
                class="link" ref="notice">Notice</router-link></el-menu-item>
            <el-menu-item class="layout" @click="layout">Layout</el-menu-item>
          </el-submenu>

          <div class="clearfix"></div>
        </el-menu>
      </el-header>
      <!-- 主要内容 -->
      <el-main>
        <router-view></router-view>
      </el-main>
      <!-- 尾部 -->
      <div style="background:#2d3a4b">
        <el-footer>
          <div class="footer">
            <div class="footer-content">
              <p class="copyright">
                Copyright © American Delivery Service 2020<br />
                <i class="iconfont icon-icon-test"></i>
                <a style="color: #fff" href="#">Back to top</a>
              </p>
              <p class="copyright pt-lf">
                E-MAIL: support@americandeliveryservice.com<br />
                TEL: +1 (732) 788-0878<br />
              </p>
            </div>
          </div>
        </el-footer>
      </div>

    </el-container>
  </div>
</template>

<script>
import { layout } from '../api/user.js'
import local from '../utils/local'
export default {
  data () {
    return {
      role: '',
      name: ''
    }
  },
  created () {
    this.role = local.get('role')
    this.name = local.get('name')
  },
  methods: {
    handleSelect (key, keyPath) { },
    // 退出
    async layout () {
      const data = await layout()
      this.$router.push('/login')
      local.clear()
    },
    addcolor () {
      setTimeout(() => {
        this.$refs.notice.$el.style.color = '#00b5f9'
      }, 20)
    },
    delcolor () {
      setTimeout(() => {
        this.$refs.notice.$el.style.color = '#fff'
      }, 20)
    }
  },
  computed: {
    calcRoute () {
      if (this.$route.path === '/noticedetail') {
        this.addcolor()
        return '/noticelist'
      } else if (this.$route.path === '/noticelist') {
        this.addcolor()
        return '/noticelist'
      } else {
        this.delcolor()
        return this.$route.path
      }
    }
  }
}
</script>

<style lang="less" scoped>
.icon-yonghu {
  color: #fff;
}

.homewrap {
  height: 100% !important;
}

.el-header {
  padding: 0;
  position: fixed;
  z-index: 999;
  width: 100%;
}

/deep/.el-main {
  overflow: visible;
  padding: 110px 20px 50px 20px;
  width: 1300px;
  margin: 0 auto;
}

.user {
  float: right !important;
}

.layout {
  width: 100%;
}

.link {
  text-decoration: none;
  color: #fff;
  display: inline-block;
}

.el-footer {
  padding: 0;
  height: auto !important;
  margin: 0 auto;
  width: 1300px;
  background: #2d3a4b;
}

.footer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px;
  padding: 0 20px;
  width: 100%;
  box-sizing: border-box;

  .footer-content {
    width: 1000px;
    display: flex;
    justify-content: space-between;
  }

  .copyright {
    font-size: 14px;
    color: #dedede;
    line-height: 35px;
    padding: 30px 0;
  }

  .pt-lf {
    margin-left: 200px;
  }

  .and {
    margin-left: 30px;
    text-align: center;
    line-height: 24px;
  }

  img {
    width: 100px;
  }
}

.account .router-link-active {
  color: #00b5f9;
}

.is-active .icon-yonghu {
  color: #00b5f9;
}

.el-menu--horizontal>.el-menu-item.is-active {
  border-bottom: 5px solid #409eff;
}

/deep/.el-menu--horizontal>.el-submenu.is-active .el-submenu__title {
  border-bottom: 5px solid #409eff;
}
</style>
