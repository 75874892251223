import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
// 设置语言
import locale from 'element-ui/lib/locale/lang/en'

// 重置样式
import './assets/css/reset.css'

// 引入字体图标
import './assets/fonts/iconfont.css'

// 引入全局组件
import './utils/components.js'

Vue.use(ElementUI, { locale })
Vue.use(ElementUI)

Vue.config.productionTip = false

var vue = new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')

export default vue
