import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

import local from '../utils/local.js'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    component: Home,
    redirect: '/home'
  },
  {
    path: '/home',
    component: Home,
    redirect: '/dashboard',
    children: [
      // 后台首页
      {
        path: '/dashboard',
        component: () => import('../views/dashboard/index.vue')
      },
      // 添加账号
      {
        path: '/addcount',
        component: () => import('../views/account/Addcount.vue')
      },
      // 账号列表
      {
        path: '/listcount',
        component: () => import('../views/account/Listcount.vue')
      },
      // 仓库管理
      {
        path: '/shelves',
        component: () => import('../views/myjod/Shelves.vue')
      },
      // 修改密码
      {
        path: '/editpassword',
        component: () => import('../views/account/Editpassword.vue')
      },
      // 偏好设置
      {
        path: '/preference',
        component: () => import('../views/preference/Preference.vue')
      },
      // 包裹列表
      {
        path: '/shipmentlist',
        component: () => import('../views/shipment/Shipmentlist.vue')
      },
      // 装运测量
      {
        path: '/shipmentmeasure',
        component: () => import('../views/shipment/Shipmentmeasure.vue')
      },
      // 放包裹
      {
        path: '/putshipment',
        component: () => import('../views/shipment/Putshipment.vue')
      },

      // 包裹统计
      {
        path: '/statistics',
        component: () => import('../views/shipment/Shipmentstatistics.vue')
      },
      // 操作日志
      {
        path: '/loglist',
        component: () => import('../views/log/Log.vue')
      },
      // 仓库工作
      {
        path: '/jobs',
        component: () => import('../views/myjod/Jobs.vue')
      },
      // 退包裹
      {
        path: '/return',
        component: () => import('../views/shipment/Return.vue')
      },
      // 公告
      {
        path: '/notice',
        component: () => import('../views/notice/index.vue'),
        redirect: '/noticelist',
        children: [
          // 公告列表
          {
            path: '/noticelist',
            component: () => import('../views/notice/Notice.vue')
          },
          // 公告详情
          {
            path: '/noticedetail',
            component: () => import('../views/notice/NoticeDetail.vue')
          }
        ]
      }
    ]
  },
  // 登录
  {
    path: '/login',
    component: () =>
      import('../views/Login.vue')
  },
  // 找回密码
  {
    path: '/forgotpassword',
    component: () => import('../views/account/Forgotpassword.vue')
  },
  // 错误
  {
    path: '*',
    redirect: '/404'
  },
  {
    path: '/404',
    component: () =>
      import('../views/Error.vue')
  }
]
const router = new VueRouter({
  routes
})

// 导航拦截
router.beforeEach((to, from, next) => {
  // 判断是否登录
  const token = local.get('hubTok')
  if (token) {
    // 如果登录了 放行
    next()
  }
  if (!token) {
    // 如果没有登录 要去登录界面 放行
    if (to.path === '/login') {
      next()
      // 如果没有登录 要去忘记密码界面 放行
    } else if (to.path === '/forgotpassword') {
      next()
    } else {
      // 如果没有登录 要去其他界面就先登录
      next('/login')
    }
  }
})

export default router
