<template>
  <el-card class="box-card">
    <!-- 头部 -->
    <div slot="header" class="title">
      <slot name="title"></slot>
    </div>
    <!-- 主要内容 -->
    <div>
      <slot name="content"></slot>
    </div>
  </el-card>
</template>
<script>
export default {}
</script>
<style lang="less" scoped>
.box-card {
  width: 80%;
  margin: 0 auto;
  .title {
    font-size: 30px;
    font-weight: bold;
    padding: 20px 0;
    // color: #537788;
    color: #333;
  }
}
</style>
