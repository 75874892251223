// 1.
// 引入axios
import axios from 'axios'
// qs 可以把对象 转换为 k=v&k=v 这种格式的字符串
import qs from 'qs'
// 引入存取本地的方法
import local from '@/utils/local.js'
// 引入相应拦截器的message
import { Message } from 'element-ui'

import NProgress from 'nprogress'
import 'nprogress/nprogress.css'

// 引入vue实例 获取this
import context from '../main.js'
// console.log(process.env.VUE_APP_ADSLINK)
// 2.
// 设置默认的请求服务地址
axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded;charset=UTF-8'
axios.defaults.baseURL = process.env.VUE_APP_ADSLINK + '/hub'
// axios.defaults.baseURL = 'https://www.americandeliveryservice.com/hub'

// 设置拦截器
axios.interceptors.request.use(config => {
  NProgress.start()
  const token = local.get('hubTok')
  if (token) {
    config.headers.Authorization = 'Bearer ' + token
  }
  return config
})

// 响应拦截器
axios.interceptors.response.use(res => {
  console.log(res)
  if (res.status === 201 && res.data.angle) {
    Message({
      message: 'Operate successfully. The receiving address is located at ' + res.data.angle + ' degrees clockwise.',
      type: 'success'
    })
  } else if (res.status === 201) {
    Message({
      message: 'Operate successfully',
      type: 'success'
    })
  }
  NProgress.done()
  return res
}, err => {
  // console.log(err)
  if (err.response.status === 401) {
    context.$router.push('/login')
  }
  NProgress.done()
  return err.response
})

// 设置超时处理
axios.defaults.timeout = 5000

// 3.把请求的方法导出去

// get 方式
export default {
  get (url, params = {}) {
    return new Promise((resolve, reject) => {
      axios
        .get(url, { params })
        .then(res => {
          resolve(res.data)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  // post 方式
  post (url, params) {
    return new Promise((resolve, reject) => {
      axios
        .post(url, qs.stringify(params))
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  // put 方式
  put (url, params) {
    return new Promise((resolve, reject) => {
      axios
        .put(url, qs.stringify(params))
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  // delete 方式
  delete (url, params) {
    return new Promise((resolve, reject) => {
      axios
        .delete(url, { params })
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  }
}
