// 引入封装的axios请求文件
import req from '../utils/request.js'

// 登录
export function login (params) {
  return req.put('/login', params)
}

// 退出
export function layout (params) {
  return req.put('/logout', params)
}

// 获取基本信息
export function info (params) {
  return req.get('/info', params)
}

// 新入库
export function receive (params) {
  return req.put('/shipment/receive', params)
}

// 确认重新创建Label
export function createNew (params) {
  return req.post('/shipment/createNew', params)
}
// 入库
export function enter (params) {
  return req.put('/shipment/enter', params)
}

// 出库
export function out (params) {
  return req.put('/shipment/out', params)
}

// 修改密码
export function changpwd (params) {
  return req.put('/chgPassword', params)
}

// 操作日志
export function logs (params) {
  return req.get('/shipment/logs', params)
}

// 添加账号
export function adduser (params) {
  return req.post('/employee/add', params)
}

// 账号列表
export function listcount (params) {
  return req.get('/employee/list', params)
}

// 修改用户
export function editUser (params) {
  return req.put('/employee/edit', params)
}

// 修改用户状态
export function editstatus (params) {
  return req.put('/employee/status', params)
}

// 获取验证码
export function code (params) {
  return req.post('/getEmailCode', params)
}

// 找回密码
export function findpassword (params) {
  return req.put('/findpassword', params)
}

// 装运测量
export function shipmentmeasure (params) {
  return req.put('/shipment/measure', params)
}

// 公告列表
export function noticelist (params) {
  return req.get('/notice/list', params)
}

// 公告详情
export function noticedetail (params) {
  return req.get('/notice/detail', params)
}

// 仓库列表
// export function shelveslist (params) {
//   return req.get('/shelves/list', params)
// }

// 仓库添加
// export function shelvesadd (params) {
//   return req.post('/shelves/add', params)
// }

// 仓库删除
// export function shelvesmove (params) {
//   return req.delete('/shelves/delete', params)
// }

// 仓库修改
// export function shelvesedit (params) {
//   return req.put('/shelves/edit', params)
// }

// 放包裹
export function putshipment (params) {
  return req.put('/shipment/put', params)
}

// 仓库条形码
// export function shelfbarcode(params) {
//   return req.get('/shelves/barcode', params)
// }

// 工作列表
export function joblist (params) {
  return req.get('/jobs/list', params)
}

// 添加包裹
export function addshipment (params) {
  return req.post('/jobs/addShipments', params)
}

// 删除包裹
export function delshipment (params) {
  return req.post('/jobs/removeShipments', params)
}

// 工作分类
export function jobsort (params) {
  return req.put('/jobs/sort', params)
}

// 放回包裹
// export function sendback (params) {
//   return req.put('/shipment/sendback', params)
// }

// 包裹丢失
export function lose (params) {
  return req.put('/shipment/lose', params)
}
// 包裹统计
export function statistic (params) {
  return req.get('/shipment/statistics', params)
}

// 包裹删除回填
export function delreturn (params) {
  return req.get('/jobs/shipments', params)
}

// 创建工作
export function createjobs (params) {
  return req.post('/jobs/create', params)
}

// 重新打印列表
export function reprintLabels (params) {
  return req.get('/shipment/reprintLabels', params)
}

// 打印
export function reprint (params) {
  return req.put('/shipment/reprinted', params)
}
// 获取所有区时
export function gettimezone (params) {
  return req.get('/preference', params)
}

// 提交设置的区时
export function saveimezone (params) {
  return req.post('/preference', params)
}

// tracking id 验证
export function checkShipment (params) {
  return req.post('/jobs/checkShipment', params)
}

// shipmentlist
export function shipments (params) {
  return req.get('/shipments', params)
}

// jobid 列表
export function jobids (params) {
  return req.get('/jobs/creating', params)
}

// shipment list 排序
export function config (params) {
  return req.get('config', params)
}
